import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import './index.css';

const LabelButton = (props) => {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        if (props) {
            setData(props)
        }
    }, [props])

    return (
        <>
            <Button label={data?.label} onClick={data?.onClick} className={`p-button-primary ${data?.buttonClass}`} />
        </>
    );
};

export default LabelButton;
