import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './screens/Login/Login';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import Home from './screens/Home/Home';
import Alert from './components/UI/Alert/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure(config);
let setLoading = null;

function App() {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  setLoading = (value) => {
    setIsLoading(value)
  }

  useEffect(() => {
    if(localStorage.getItem("user_signed")){
      setUser(true)
    }
  }, [])

  return (
    <div className="App">
      <Alert />
      {!user && <Login setUser={setUser} />}
      {user && <Home />}
      {isLoading && <div className='spinning-loading'> <i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', color: "#FF5C00" }}></i> </div>}
    </div>
  );
}

export default App;

export function setIsLoaing(value) {
  setLoading(value)
}