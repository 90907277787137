import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Overlay(props) {
  const renderTooltip = (data) => (
    <Tooltip id={data.id} {...data}>
      {props.label}
    </Tooltip>
  );
  
  return (
    <OverlayTrigger
      placement={props.placement ? props.placement : "auto"}
      delay={{ show: 100, hide: 100 }}
      show={props.hide}
      overlay={renderTooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
}

export default Overlay;