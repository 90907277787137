import React, { useState } from 'react';
import './index.css';
import img from "../../../assets/logo-squares.png"
import UserMenu from '../UserMenu/UserMenu';
import { Link } from 'react-router-dom';
import Overlay from '../../Overlay/Overlay';

const sideBarItens = [
    {
        label: "Dashboard",
        icon: "icon-dash",
        to: "dashboard"
    },
    {
        label: "Quadras",
        icon: "icon-court",
        to: "courts"
    },
    {
        label: "Usuários",
        icon: "icon-user",
        to: "users"
    },
    {
        label: "Torneios",
        icon: "icon-troph",
        to: "tournaments"
    }
]

const active = window.location.pathname;

const SideBar = () => {
    const [sidebarOpen, setSideBarOpen] = useState(false)
    const [page, setPage] = useState(active !== "/" ? active : "/dashboard")

    return (
        <div className="sidebar-container">
            <div className={`sidebar ${sidebarOpen ? "" : "closed"}`}>
                <div className="title-side-bar">
                    <img className="title-side-bar-image" src={img} alt="image_icon" />
                    {sidebarOpen && <h3>Squares</h3>}
                </div>
                <div className='sidebar-change-size' onClick={() => {
                    setSideBarOpen(!sidebarOpen)
                }}>
                    <Overlay label={sidebarOpen ? "Fechar" : "Abrir"} id="click-sidebar-element" placement="right">
                        <i id="click-sidebar-element" className="pi pi-bars icon-side-bar-click custom-target-icon-close p-text-secondary p-overlay-badge"></i>
                    </Overlay>

                </div>

                <div className="sidebar-items">
                    <ul>
                        {sideBarItens.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link to={item.to} onClick={() => setPage(item.to)}>
                                        <div className={`sidebar-icon-li ${page.includes(item.to) ? "active" : ""}`}>
                                            <Overlay label={item.label} id={`item-sidebar-element-${item.to}`} placement="right">
                                                <i id={`item-sidebar-element-${item.to}`} className={`icon icon-large ${item.icon} custom-target-icon p-text-secondary p-overlay-badge`} />
                                            </Overlay>
                                            {sidebarOpen && <span>
                                                {item.label}
                                            </span>}
                                        </div>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <UserMenu sidebarOpen={sidebarOpen} />
            </div>

        </div >
    );
};

export default SideBar;
