
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import "./index.css";

export default function LineChart(props) {
    const [chartData, setChartData] = useState({});

    const textColor = window.getComputedStyle( document.body ,null).getPropertyValue('--text-color-quad');  
    const textColorSecondary = window.getComputedStyle( document.body ,null).getPropertyValue('--text-color-quad');  
    const surfaceBorder = window.getComputedStyle( document.body ,null).getPropertyValue('--text-color-quad');  

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };

    useEffect(() => {
        if (props.data) {
            setChartData(props.data)
        }
    }, [props.data])

    return (
        <div className="card">
            <Chart type="line" data={chartData} options={options} className={props.className ? props.className : 'dash-chart-line'} />
        </div>
    )
}
