import React, { useRef } from 'react';
import './index.css';
import { Toast } from 'primereact/toast';

let toast = null;

function Alert() {
    toast = useRef(null);
    return (
        <div>
            <Toast ref={toast} />
        </div>
    );
}

export default Alert;

export function showToast(type, message) {

    const messages = {
        "success": "Sucesso",
        "info": "Informação",
        "warn": "Warning",
        "error": "Erro"
    }

    toast.current.show({ severity: type, summary: messages[type], detail: message, life: 3000 });
}
