import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import './index.css';

const TextInput = (props) => {
    const [data, setData] = useState(undefined);
    const [defaultValue, setDefaultValue] = useState()

    useEffect(() => {
        if (props) {
            setData(props)
            if (props.defaultValue || props.defaultValue === "") {
                setDefaultValue(props.defaultValue)
            }
        }
    }, [props])

    return (
        <div className={`p-field ${data?.className}`}>
            <label htmlFor={data?.id}>{data?.label}</label>
            <span className="p-input-icon-right span-icon-input">
                {data?.icon && <i className={`pi ${data?.icon}`} />}
                <InputText id={data?.id} value={defaultValue} placeholder={data?.placeholder} onChange={(e) => {
                    data?.onChange(e.target.value)
                }} />
            </span>
        </div>
    );
};

export default TextInput;
