import React from 'react';
import ReactDOM from 'react-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.css';
import 'primeicons/primeicons.css';
import App from './App';
import 'primeicons/primeicons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../src/components/IconsLib/styles/index.css';
import { BrowserRouter } from 'react-router-dom';
import "./colors.css";

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  );
  