import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import "./index.css";

export default function PieChart(props) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        if (window) {
            const textColor = window.getComputedStyle(document.body, null).getPropertyValue('--text-color-quad');
            const theme = localStorage.getItem("quad-theme")
            const labels = []
            const dataValues = []

            props.data.total_courts.forEach(element => {
                labels.push(element.title)
                dataValues.push(element.value)
            });

            const data = {
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: ["#E15201", "#4595E5", "#D99F2B", "#3DCCB4"],
                        hoverBackgroundColor: ["#e15201c7", "#0065b7d4", "#d99f2bc7", "#3dccb4ad"]
                    }
                ]
            }
            const options = {
                plugins: {
                    legend: {
                        labels: {
                            color: textColor ? textColor : theme && theme.includes("light") ? "#000" : "#fff",
                            usePointStyle: true
                        }
                    }
                }
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [props]);

    return (
        <div className="card flex justify-content-center pie-chart-quad">
            <Chart type="pie" data={chartData} options={chartOptions} />
        </div>
    )
}