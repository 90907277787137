import React, { useEffect } from 'react';
import './index.css';
import SideBar from '../../components/Layout/Sidebar/SideBar';
import AppRoutes from '../../components/Routes/Routes';


const Home = () => {

    useEffect(() => {
        installTheme()
    }, [])

    return (
        <div className="app-container">
            <SideBar />
            <div className="main-content">
                <div className="page-content">
                    <AppRoutes />
                </div>
            </div>
        </div>
    );
};

export default Home;

function installTheme(){
    if(typeof window !== "undefined"){
        const body = document.getElementsByTagName("body");
        const bodyElement = body.length ? body[0] : null;
        const theme = localStorage.getItem("quad-theme") || "light-theme";
        if(!bodyElement.classList.contains(theme)){
            bodyElement.classList.add(theme)
        }
    }
}
