import React from 'react';
import LineChart from '../../components/Charts/LineChart/LineChart';
import "./index.css";
import { data_mock } from './utils';
import CardChart from '../../components/Charts/CardChart/CardChart';
import PieChart from '../../components/Charts/PieChart/PieChart';

function Dashboard() {
  return (
    <div className='dashboard-page'>
      <div className="title-page-list">
        <span>Dashboard</span>
      </div>
      <div className='dash-area'>
        <div className='cards-dash-area'>
          <CardChart data={getMockDataCard(data_mock["players"]["data"], "Jogadores", 0)} title={"Jogadores"} icon={"icon-user"} total={data_mock["players"]["total"]} />
          <CardChart data={getMockDataCard(data_mock["profit"]["data"], 'Lucro', 1)} title={"Lucro"} icon={"icon-troph"} total={data_mock["profit"]["total"]} />
          <CardChart data={getMockDataCard(data_mock["courts"]["data"], "Uso quadras", 2)} title={"Uso quadras"} icon={"icon-court"} total={data_mock["courts"]["total"]} />
          <CardChart data={getMockDataCard(data_mock["visitors"]["data"], "Visitantes", 3)} title={"Visitantes"} icon={"icon-user"} total={data_mock["visitors"]["total"]} />
        </div>

        <div className='cards-dash-area-2'>
          <div className={"chart-container-dash"}>
            <span>Rendimento mensal</span>
            <LineChart data={getMockData(data_mock)} />
          </div>

          <div className={"chart-container-dash"}>
            <span>Lucro por quadra</span>
            <PieChart data={getMockData(data_mock)}/>
          </div>
        </div>


      </div>

    </div>
  );
}

export default Dashboard;

const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

const colors = ["#E15201", "#4595E5", "#D99F2B", "#3DCCB4"]

function somarArray(array) {
  return array.reduce((acumulador, valorAtual) => acumulador + valorAtual, 0);
}
function getMockData(unfformated_data) {
  const performance_data = unfformated_data["performance"]
  const labels = []
  const datasets = []
  const total_courts = []

  let index = 0
  Object.keys(performance_data).forEach(key => {
    total_courts.push({
      "title": key,
      "value": somarArray(performance_data[key]["value"])
    })
    datasets.push({
      label: key,
      fill: false,
      borderColor: colors[index],
      tension: 0.4,
      data: performance_data[key]["value"],
    })
    index += 1;
  })


  for (let i = 0; i <= datasets[0]["data"].length; i++) {
    labels.push(months[i + 1])
  }

  return { datasets, labels, total_courts }
}

function getMockDataCard(unfformated_data, key, number) {
  const performance_data = unfformated_data
  const labels = []
  const datasets = []
  const values = []

  performance_data.forEach(item => {
    labels.push(months[item["month"]])
    values.push(item["value"])
  })

  datasets.push({
    label: key,
    fill: false,
    borderColor: colors[number],
    tension: 0.4,
    data: values
  })

  return { datasets, labels }
}