export const data_mock = {
    players: {
        total: 105,
        data: [
            { month: 1, value: 10 },
            { month: 2, value: 50 },
            { month: 3, value: 80 },
            { month: 4, value: 102 },
        ]
    },
    profit: {
        total: 20000,
        data: [
            { month: 1, value: 1000 },
            { month: 2, value: 5000 },
            { month: 3, value: 8000 },
            { month: 4, value: 20000 },
        ]
    },
    courts: {
        total: 70,
        data: [
            { month: 1, value: 40 },
            { month: 2, value: 60 },
            { month: 3, value: 65 },
            { month: 4, value: 70 },
        ]
    },
    visitors: {
        total: 35,
        data: [
            { month: 1, value: 7 },
            { month: 2, value: 5 },
            { month: 3, value: 20 },
            { month: 4, value: 35 },
        ]
    },
    performance: {
        "Quadra A": { month: 1, value: [7, 8, 10, 34], label: "First Dataset" },
        "Quadra B": { month: 1, value: [2, 5, 19, 50], label: "Second Dataset" }
    }
}