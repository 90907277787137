import React, { useEffect, useState } from 'react';
import { Password } from 'primereact/password';
import './index.css';

const PasswordInput = (props) => {
    const [data, setData] = useState(undefined);
    const [defaultValue, setDefaultValue] = useState()

    useEffect(() => {
        if (props) {
            setData(props)
            if (props.defaultValue || props.defaultValue === "") {
                setDefaultValue(props.defaultValue)
            }
        }
    }, [props])

    return (
        <div className={`p-field ${data?.className}`}>
            <label htmlFor={data?.id}>{data?.label}</label>
            <span className="p-input-icon-right span-icon-input">
                {data?.icon && <i className={`pi ${data?.icon}`} />}
                <Password
                    id={data?.id}
                    value={defaultValue}
                    onChange={(e) => data?.onChange(e.target.value)}
                    feedback={data?.feedback}
                    placeholder={data?.placeholder}
                    icon={"pi-user"}
                />
            </span>
        </div>
    );
};

export default PasswordInput;
