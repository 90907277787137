import React from 'react';

function Tournaments() {
  return (
    <div>
        Tournaments page
    </div>
  );
}

export default Tournaments;
