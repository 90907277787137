import React, { useState } from 'react';
import './index.css';
import { handleLogin, handleSetPassword } from './utils';
import TextInput from '../../components/UI/inputs/Textinput/TextInput';
import PasswordInput from '../../components/UI/inputs/PasswordInput/PasswordInput';
import LabelButton from '../../components/UI/Buttons/LabelButton/LabelButton';


const Login = ({ setUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState(null)
    const [nextStep, setNextStep] = useState("login")

    const handleLoginCall = async () => {
        handleLogin(setUser, setResponse, setNextStep, email, password)
    };

    const handleSetPasswordCall = async () => {
        handleSetPassword(response, password)
    };

    return (
        <div className="login-container">
            <div className="left-panel"></div>
            <div className="right-panel">
                <div className="login-form">
                    <h2>Login</h2>
                    {nextStep === "login" && <>
                        <>
                            <TextInput
                                id={"email"}
                                defaultValue={email}
                                onChange={setEmail}
                                label={"Email"}
                                placeholder={"Digite seu email"}
                                icon={"pi-user"}
                            />
                            <PasswordInput
                                id={"password"}
                                defaultValue={password}
                                onChange={setPassword}
                                label={"Senha"}
                                placeholder={"Digite sua senha"}
                                feedback={false}
                                icon={"pi-lock"}
                            />
                        </>
                    </>}
                    {nextStep === "new_password" && <>
                        <>
                            <PasswordInput
                                id={"password"}
                                defaultValue={password}
                                onChange={setPassword}
                                label={"Senha"}
                                placeholder={"Digite uma nova senha"}
                                feedback={false}
                                icon={"pi-lock"}
                            />
                        </>
                    </>}
                    <LabelButton 
                        label={"Entrar"}
                        onClick={nextStep === "login" ? handleLoginCall : handleSetPasswordCall}
                        buttonClass={"p-button-primary"}
                    />
                </div>
            </div>
        </div>
    );
};

export default Login;
