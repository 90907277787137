const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f169b4d2-6ed4-4e9a-9334-71f9212ace0f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wsVGrL8In",
    "aws_user_pools_web_client_id": "6ec0mtn0coe28f36gbkdckq8en",
    "aws_user_pools_web_secret_id": "1sf80d4rtb9jqlahsq0j3fmj8ndp2ss160bkderd21tliec6bfsc"
};

export default awsmobile;
