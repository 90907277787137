
import React from 'react';
import "./index.css";
import Overlay from '../../Overlay/Overlay';

export default function CardChart(props) {
    return (
        <div className="cardchart">
            <div className='cardchart-container'>
                <div className='cardchart-icon'>
                    <div className='icon-card-chart'>
                        <span className='cardchart-value-title'>{props.title}</span>
                        <Overlay label={props.title} id={`chart-element-${props.title}`} placement="right">
                            <i className='pi pi-info-circle cardchart-value-title-icon custom-target-icon-card p-text-secondary p-overlay-badge'/>
                        </Overlay>
                    </div>
                </div>
                <div className='cardchart-area'>
                    <div className='cardchart-value'>
                        <span className='cardchart-value-total'>{props.total}</span>
                    </div>
                </div >

            </div>
        </div>
    )
}
