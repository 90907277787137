import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../screens/Dashboard/Dashboard';
import Users from '../../screens/Users/Users';
import Tournaments from '../../screens/Tournaments/Tournaments';
import Courts from '../../screens/Courts/Courts';

function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/courts" element={<Courts />} />
        <Route path="/" element={<Dashboard />} /> 
      </Routes>
    </div>
  );
}

export default AppRoutes;
