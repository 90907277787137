import React from 'react';

function Users() {
  return (
    <div>
        Users page
    </div>
  );
}

export default Users;
