import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import './index.css';
import { Menu } from 'primereact/menu';
import Overlay from '../../Overlay/Overlay';

const menuItems = [
    {
        label: 'Mudar tema',
        icon: 'pi pi-palette',
        command: () => {
            const theme = localStorage.getItem("quad-theme")
            if (theme) {
                if (theme.includes("light")) {
                    localStorage.setItem("quad-theme", "dark-theme")
                } else {
                    localStorage.setItem("quad-theme", "light-theme")
                }
            } else {
                localStorage.setItem("quad-theme", "light-theme")
            }
            window.location.reload()
        }
    },
    {
        label: 'Sair',
        icon: 'pi pi-sign-out',
        command: () => {
            window.location.href = "/"
            localStorage.clear()
        }
    }
];

const UserMenu = (props) => {
    const userdata = JSON.parse(localStorage.getItem("user_attributes"))
    const initials = userdata && userdata["initials"] ? userdata["initials"] : ""
    const menu = useRef(null);

    return (
        <>
            <div className="logout-button">
                <div className={`logout-button-container ${props.sidebarOpen ? "" : "closed"}`}>
                    <div>
                        <Overlay label={`${userdata["name"]} ${userdata["family_name"]}`} id={`usermenu-sidebar-element`} placement="right" hide={props.sidebarOpen === true ? false : undefined}>
                            <div id={`usermenu-sidebar-element`}>
                                <Menu model={menuItems} popup ref={menu} />
                                <Button label={initials} onClick={(event) => menu.current.toggle(event)} className="custom-target-icon-menu p-text-secondary p-overlay-badge" />
                            </div>
                        </Overlay>
                    </div>
                    {props && props.sidebarOpen && <label className='userMenu-user-name'>
                        {`${userdata["name"]} ${userdata["family_name"]}`}
                    </label>}
                </div>

            </div>
        </>
    );
};

export default UserMenu;
